/**
 * Return a date object with proper UTC time zone offset
 *
 * @param {string} dateString
 */

export default function (dateString: string): Date {
  const date = new Date(dateString)
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
}
